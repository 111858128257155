var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "3" } },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    [
                      _vm._v(" Approval Levels "),
                      _c("v-spacer"),
                      _vm.me.is.superAdmin
                        ? _c(
                            "v-row",
                            {
                              attrs: {
                                align: "center",
                                justify: "end",
                                "no-gutters": "",
                              },
                            },
                            [
                              !_vm.isBulkDelete && _vm.items.length
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass: "mr-4",
                                      attrs: {
                                        color: "error",
                                        dense: "",
                                        text: "",
                                        elevation: "0",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.setBulkDelete()
                                        },
                                      },
                                    },
                                    [
                                      _c("v-icon", { attrs: { left: "" } }, [
                                        _vm._v("mdi-delete"),
                                      ]),
                                      _vm._v(" Bulk Delete "),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              !_vm.isBulkDelete
                                ? _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        elevation: "2",
                                        color: "primary",
                                        fab: "",
                                        small: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.createItem()
                                        },
                                      },
                                    },
                                    [_c("v-icon", [_vm._v("mdi-plus")])],
                                    1
                                  )
                                : _vm._e(),
                              _vm.isBulkDelete
                                ? _c(
                                    "v-row",
                                    {
                                      attrs: {
                                        align: "center",
                                        justify: "end",
                                        "no-gutters": "",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "mr-2",
                                          attrs: { text: "", dense: "" },
                                          on: {
                                            click: function ($event) {
                                              _vm.isBulkDelete = false
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { left: "" } },
                                            [_vm._v("mdi-cancel")]
                                          ),
                                          _vm._v(" Cancel "),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "error",
                                            elevation: "0",
                                            dense: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.bulkDelete()
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { left: "" } },
                                            [_vm._v("mdi-delete")]
                                          ),
                                          _vm._v(" Delete Bulk "),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-list",
                    { staticClass: "scrollable-list", attrs: { shaped: "" } },
                    [
                      _vm.approvalLevelsLoading
                        ? _c(
                            "div",
                            { staticClass: "text-center" },
                            [
                              _c("v-progress-circular", {
                                attrs: { indeterminate: "", color: "primary" },
                              }),
                            ],
                            1
                          )
                        : _c(
                            "v-list-item-group",
                            { attrs: { color: "primary" } },
                            [
                              _c(
                                "draggable",
                                {
                                  attrs: {
                                    disabled:
                                      !_vm.me.is.superAdmin || _vm.isBulkDelete,
                                  },
                                  on: { sort: _vm.handleSort },
                                  model: {
                                    value: _vm.items,
                                    callback: function ($$v) {
                                      _vm.items = $$v
                                    },
                                    expression: "items",
                                  },
                                },
                                _vm._l(_vm.items, function (level, i) {
                                  return _c(
                                    "v-list-item",
                                    {
                                      key: i,
                                      on: {
                                        click: function ($event) {
                                          return _vm.editItem(level)
                                        },
                                      },
                                    },
                                    [
                                      _vm.isBulkDelete
                                        ? _c("v-checkbox", {
                                            attrs: {
                                              label: level.name,
                                              value: level.id,
                                            },
                                            model: {
                                              value: _vm.idsToDelete,
                                              callback: function ($$v) {
                                                _vm.idsToDelete = $$v
                                              },
                                              expression: "idsToDelete",
                                            },
                                          })
                                        : _vm._e(),
                                      !_vm.isBulkDelete
                                        ? _c(
                                            "v-list-item-icon",
                                            [
                                              _c("v-icon", [
                                                _vm._v(
                                                  "mdi-drag-horizontal-variant"
                                                ),
                                              ]),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      !_vm.isBulkDelete
                                        ? _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-list-item-title", [
                                                _vm._v(_vm._s(level.name)),
                                              ]),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "9" } },
            [
              _vm.me.is.superAdmin && _vm.showable
                ? _c(
                    "div",
                    { staticClass: "d-flex justify-space-between mb-2" },
                    [
                      _c(
                        "v-btn",
                        {
                          on: {
                            click: function ($event) {
                              _vm.editedItem = {}
                              _vm.showable = false
                            },
                          },
                        },
                        [_vm._v(" Cancel ")]
                      ),
                      _c(
                        "div",
                        [
                          _c(
                            "v-btn",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.isSaveDisabled,
                                  expression: "isSaveDisabled",
                                },
                              ],
                              staticClass: "mx-2",
                              attrs: { dark: "", color: "success" },
                              on: {
                                click: function ($event) {
                                  return _vm.saveItem()
                                },
                              },
                            },
                            [_vm._v(" Save ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.editedItem.id,
                                  expression: "editedItem.id",
                                },
                              ],
                              staticClass: "mx-2",
                              attrs: { dark: "", color: "error" },
                              on: {
                                click: function ($event) {
                                  return _vm.deleteItem()
                                },
                              },
                            },
                            [_vm._v(" Delete ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-card",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showable,
                      expression: "showable",
                    },
                  ],
                },
                [
                  _c(
                    "v-form",
                    {
                      attrs: {
                        readonly:
                          _vm.editedItem.id > 0 &&
                          !_vm.me.is.superAdmin &&
                          !_vm.me.is.transportationAdmin,
                      },
                    },
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "content-title" },
                        [
                          _c("span", { staticClass: "text-h5" }, [
                            _vm._v(
                              _vm._s(
                                _vm.editedItem.id
                                  ? "Edit Approval Level"
                                  : "New Approval Level"
                              )
                            ),
                          ]),
                          _c("v-badge", {
                            attrs: {
                              color: "error",
                              content: "New",
                              "offset-x": "0",
                              value: !_vm.editedItem.id,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-container",
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "10" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          disabled: !_vm.isFieldEnabled,
                                          rules: [_vm.validateName],
                                          label: "Name",
                                        },
                                        model: {
                                          value: _vm.editedItem.name,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.editedItem,
                                              "name",
                                              $$v
                                            )
                                          },
                                          expression: "editedItem.name",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "6" } },
                                    [
                                      _c(
                                        "v-radio-group",
                                        {
                                          attrs: {
                                            disabled: !_vm.isFieldEnabled,
                                            row: "",
                                            mandatory: "",
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "label",
                                              fn: function () {
                                                return [
                                                  _c("div", [
                                                    _vm._v(
                                                      "Include Overnight / OOS Trips?"
                                                    ),
                                                  ]),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                          model: {
                                            value:
                                              _vm.editedItem.incOvernightOOS,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.editedItem,
                                                "incOvernightOOS",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "editedItem.incOvernightOOS",
                                          },
                                        },
                                        [
                                          _c("v-radio", {
                                            attrs: { label: "Yes", value: 1 },
                                          }),
                                          _c("v-radio", {
                                            attrs: { label: "No", value: 0 },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "6" } },
                                    [
                                      _c(
                                        "v-radio-group",
                                        {
                                          attrs: {
                                            disabled: !_vm.isFieldEnabled,
                                            row: "",
                                            mandatory: "",
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "label",
                                              fn: function () {
                                                return [
                                                  _c("div", [
                                                    _vm._v(
                                                      "Required for Vehicle Assignment?"
                                                    ),
                                                  ]),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                          model: {
                                            value: _vm.editedItem.assignBusses,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.editedItem,
                                                "assignBusses",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "editedItem.assignBusses",
                                          },
                                        },
                                        [
                                          _c("v-radio", {
                                            attrs: { label: "Yes", value: 1 },
                                          }),
                                          _c("v-radio", {
                                            attrs: { label: "No", value: 0 },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "6" } },
                                    [
                                      _c(
                                        "v-radio-group",
                                        {
                                          attrs: {
                                            disabled: !_vm.isFieldEnabled,
                                            row: "",
                                            mandatory: "",
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "label",
                                              fn: function () {
                                                return [
                                                  _c("div", [
                                                    _vm._v("Board Report"),
                                                  ]),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                          model: {
                                            value: _vm.editedItem.boardReport,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.editedItem,
                                                "boardReport",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "editedItem.boardReport",
                                          },
                                        },
                                        [
                                          _c("v-radio", {
                                            attrs: { label: "Yes", value: 1 },
                                          }),
                                          _c("v-radio", {
                                            attrs: { label: "No", value: 0 },
                                          }),
                                          _c("v-radio", {
                                            attrs: {
                                              label: "OOS Only",
                                              value: 2,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "6" } },
                                    [
                                      _c(
                                        "v-radio-group",
                                        {
                                          attrs: {
                                            disabled: !_vm.isFieldEnabled,
                                            row: "",
                                            mandatory: "",
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "label",
                                              fn: function () {
                                                return [
                                                  _c("div", [
                                                    _vm._v("Notify Approver"),
                                                  ]),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                          model: {
                                            value: _vm.editedItem.notify,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.editedItem,
                                                "notify",
                                                $$v
                                              )
                                            },
                                            expression: "editedItem.notify",
                                          },
                                        },
                                        [
                                          _c("v-radio", {
                                            attrs: { label: "Yes", value: 1 },
                                          }),
                                          _c("v-radio", {
                                            attrs: { label: "No", value: 0 },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                { attrs: { dense: "" } },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "8" } },
                                    [
                                      _c("v-autocomplete", {
                                        attrs: {
                                          multiple: "",
                                          items: _vm.tripTypes,
                                          label: "Trip Types",
                                          "item-text": "name",
                                          "item-value": "id",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "selection",
                                            fn: function ({ item, index }) {
                                              return [
                                                index < 2
                                                  ? _c(
                                                      "v-chip",
                                                      {
                                                        attrs: {
                                                          disabled:
                                                            !_vm.isFieldEnabled,
                                                        },
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(item.name)
                                                          ),
                                                        ]),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                index === 2
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "grey--text text-caption",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " (+" +
                                                            _vm._s(
                                                              _vm.editedItem
                                                                .tripTypes
                                                                .length - 2
                                                            ) +
                                                            " others) "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            },
                                          },
                                        ]),
                                        model: {
                                          value: _vm.editedItem.tripTypes,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.editedItem,
                                              "tripTypes",
                                              $$v
                                            )
                                          },
                                          expression: "editedItem.tripTypes",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "2" } },
                                    [
                                      _c("v-checkbox", {
                                        attrs: {
                                          disabled: !_vm.isFieldEnabled,
                                          label: "All",
                                        },
                                        model: {
                                          value: _vm.areTripTypesAllSelected,
                                          callback: function ($$v) {
                                            _vm.areTripTypesAllSelected = $$v
                                          },
                                          expression: "areTripTypesAllSelected",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "12" } },
                                    [
                                      _c("h5", [_vm._v("Approval Criteria")]),
                                      _c(
                                        "v-card",
                                        { staticClass: "ma-2 pa-2" },
                                        [
                                          _c(
                                            "v-card-text",
                                            [
                                              _c(
                                                "v-row",
                                                [
                                                  _vm._l(
                                                    _vm.editedItem.criteria,
                                                    function (
                                                      criteria,
                                                      criteriaIndex
                                                    ) {
                                                      return [
                                                        !criteria.deleted
                                                          ? _c(
                                                              "Chip",
                                                              {
                                                                key: criteriaIndex,
                                                                staticClass:
                                                                  "ma-2 pa-2 full-chip",
                                                                attrs: {
                                                                  close:
                                                                    _vm.isFieldEnabled,
                                                                  "close-icon":
                                                                    "mdi-delete",
                                                                  large: "",
                                                                  wrap: "",
                                                                },
                                                                on: {
                                                                  "click:close":
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.deleteCriteria(
                                                                        criteriaIndex
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      criteria.label
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    }
                                                  ),
                                                  _vm.editedItem.criteria &&
                                                  _vm.editedItem.criteria
                                                    .length &&
                                                  _vm.isFieldEnabled
                                                    ? _c(
                                                        "v-chip",
                                                        {
                                                          staticClass:
                                                            "ma-2 pa-2",
                                                          attrs: {
                                                            close: "",
                                                            "close-icon":
                                                              "mdi-close",
                                                            color: "error",
                                                            large: "",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.removeAllCriteria()
                                                            },
                                                          },
                                                        },
                                                        [_vm._v(" Remove All ")]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                2
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-card-actions",
                                            [
                                              _c(
                                                "v-chip",
                                                {
                                                  staticClass: "ma-2",
                                                  attrs: {
                                                    label: "",
                                                    outlined: "",
                                                    small: "",
                                                    color: "info",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        small: "",
                                                        left: "",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "mdi-information-outline"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(
                                                    " Reminder: Click the 'Save' button to apply your changes. "
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c("v-spacer"),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    disabled:
                                                      !_vm.isFieldEnabled,
                                                    color: "primary",
                                                    text: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.newCriteria()
                                                    },
                                                  },
                                                },
                                                [_vm._v(" Edit Criteria ")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm.showApprovers
                                ? _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", md: "12" } },
                                        [
                                          _c("h5", [_vm._v("Approvers")]),
                                          _c(
                                            "v-card",
                                            { staticClass: "ma-2 pa-2" },
                                            [
                                              _c(
                                                "v-card-text",
                                                [
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: {
                                                            cols: "12",
                                                            md: "12",
                                                          },
                                                        },
                                                        [
                                                          _c("v-simple-table", [
                                                            _c("thead", [
                                                              _c("tr", [
                                                                _c("th"),
                                                                _c("th", [
                                                                  _vm._v(
                                                                    "Name"
                                                                  ),
                                                                ]),
                                                                _c("th", [
                                                                  _vm._v(
                                                                    "Email"
                                                                  ),
                                                                ]),
                                                                _c("th", [
                                                                  _vm._v(
                                                                    "Trip Types"
                                                                  ),
                                                                ]),
                                                                _c("th", [
                                                                  _vm._v(
                                                                    "Locations"
                                                                  ),
                                                                ]),
                                                              ]),
                                                            ]),
                                                            _c(
                                                              "tbody",
                                                              _vm._l(
                                                                _vm.editedItem
                                                                  .approvers,
                                                                function (
                                                                  approver,
                                                                  i
                                                                ) {
                                                                  return _c(
                                                                    "tr",
                                                                    {
                                                                      directives:
                                                                        [
                                                                          {
                                                                            name: "show",
                                                                            rawName:
                                                                              "v-show",
                                                                            value:
                                                                              !approver.delete,
                                                                            expression:
                                                                              "!approver.delete",
                                                                          },
                                                                        ],
                                                                      key: i,
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "td",
                                                                        {
                                                                          staticClass:
                                                                            "isPrimary",
                                                                        },
                                                                        [
                                                                          approver?.isPrimary
                                                                            ? _c(
                                                                                "v-icon",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      color:
                                                                                        "#F27501",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "mdi-star"
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c("td", [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              approver.userDisplayName
                                                                                ? approver.userDisplayName
                                                                                : "Unregistered User"
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]),
                                                                      _c("td", [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            approver.userId
                                                                              ? approver.userEmail
                                                                              : ""
                                                                          )
                                                                        ),
                                                                      ]),
                                                                      _c("td", [
                                                                        approver
                                                                          .tripTypeIds
                                                                          .length ==
                                                                        _vm
                                                                          .tripTypes
                                                                          .length
                                                                          ? _c(
                                                                              "div",
                                                                              [
                                                                                _vm._v(
                                                                                  "All"
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _c(
                                                                              "div",
                                                                              _vm._l(
                                                                                approver.tripTypeIds,
                                                                                function (
                                                                                  tt,
                                                                                  s
                                                                                ) {
                                                                                  return _c(
                                                                                    "span",
                                                                                    {
                                                                                      key: s,
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " " +
                                                                                          _vm._s(
                                                                                            _vm
                                                                                              .tripTypesById[
                                                                                              tt
                                                                                            ]
                                                                                              .name
                                                                                          ) +
                                                                                          " "
                                                                                      ),
                                                                                      s <
                                                                                      approver
                                                                                        .tripTypeIds
                                                                                        .length -
                                                                                        1
                                                                                        ? _c(
                                                                                            "span",
                                                                                            [
                                                                                              _vm._v(
                                                                                                ", "
                                                                                              ),
                                                                                            ]
                                                                                          )
                                                                                        : _vm._e(),
                                                                                    ]
                                                                                  )
                                                                                }
                                                                              ),
                                                                              0
                                                                            ),
                                                                      ]),
                                                                      _c("td", [
                                                                        approver
                                                                          .locationIds
                                                                          .length ==
                                                                        _vm
                                                                          .schools
                                                                          .length
                                                                          ? _c(
                                                                              "div",
                                                                              [
                                                                                _vm._v(
                                                                                  "All"
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _c(
                                                                              "div",
                                                                              _vm._l(
                                                                                approver.locationIds,
                                                                                function (
                                                                                  l,
                                                                                  i
                                                                                ) {
                                                                                  return _c(
                                                                                    "span",
                                                                                    {
                                                                                      key: i,
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " " +
                                                                                          _vm._s(
                                                                                            _vm
                                                                                              .locationsById[
                                                                                              l
                                                                                            ]
                                                                                              ?.name
                                                                                          ) +
                                                                                          " "
                                                                                      ),
                                                                                      i <
                                                                                      approver
                                                                                        .locationIds
                                                                                        .length -
                                                                                        1
                                                                                        ? _c(
                                                                                            "span",
                                                                                            [
                                                                                              _vm._v(
                                                                                                ", "
                                                                                              ),
                                                                                            ]
                                                                                          )
                                                                                        : _vm._e(),
                                                                                    ]
                                                                                  )
                                                                                }
                                                                              ),
                                                                              0
                                                                            ),
                                                                      ]),
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              0
                                                            ),
                                                          ]),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { maxWidth: "1000" },
          model: {
            value: _vm.acDialog,
            callback: function ($$v) {
              _vm.acDialog = $$v
            },
            expression: "acDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-text",
                [
                  _c("approver-creator", {
                    attrs: {
                      value: _vm.editedApprover,
                      approvalLevelId: _vm.editedItem.id,
                      users: _vm.users,
                      filteredTripTypes: _vm.editedItem.tripTypes,
                      refresh: _vm.refresh,
                      dialog: _vm.acDialog,
                      close: _vm.closeDialog,
                      readonly:
                        !_vm.me.is.superAdmin && !_vm.me.is.transportationAdmin,
                      isDialog: "",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("criteria-builder", {
        ref: "cbuilder",
        attrs: { tripTypes: _vm.editedItem.tripTypes },
        model: {
          value: _vm.editedItem.criteria,
          callback: function ($$v) {
            _vm.$set(_vm.editedItem, "criteria", $$v)
          },
          expression: "editedItem.criteria",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }